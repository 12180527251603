<template>
    <div>
        <form>
            <div class="form-group mb-4">
               <label>Type your full name which will be your "signature" to verify application terms and agreement <span class="required-indc">*</span></label>
               <input type="text" class="reg-field" placeholder="eg: John Doe">
            </div>      

            <div class="form-group row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6  mb-4 ">
                    <label>Signature Date <span class="required-indc">*</span></label>                
                    <date-picker :lang="lang" input-class="reg-field" placeholder="DD/MM/YYYY"></date-picker>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6  mb-4 ">
                    <label>Verification Code</label>                
                    <input type="text" class="reg-field verification-disabled" value="x6b4q" disabled>
                </div>
            </div>   

            <div class="form-group">
               <label>Verify Code <span class="required-indc">*</span></label>
               <input type="text" class="reg-field" placeholder="Enter Verification Code Here">
            </div>    
        </form>
        <div class="action-btn split-action mt-5">
            <router-link to="/registration/group-contribution" class="button-black">Back</router-link>
            <div class="action-next">
                <router-link to="" class="button-blue">Reset</router-link>
                <router-link to="/registration/register-success" class="button-green submit-btn">Submit</router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SignatureForm'
}
</script>